export const WHATSAPP_URL = {
  template: process.env.HUB_SERVICE_URL + '/api/core/v1/templates',
  category: process.env.HUB_SERVICE_URL + '/api/core/v1/templates/category',
  category_v1_1:
    process.env.HUB_SERVICE_URL + '/api/core/v1.1/templates/category',
  language: process.env.HUB_SERVICE_URL + '/api/core/v1/templates/languages',
  broadcast:
    process.env.HUB_SERVICE_URL + '/api/core/v1/whatsapp/broadcast_message',
  list_phone_number: process.env.HUB_SERVICE_URL + '/api/core/v1/integrations',
  info: process.env.HUB_SERVICE_URL + '/api/core/v1/organizations/me',
  responder: process.env.HUB_SERVICE_URL + '/api/core/v1/auto_responder',
  tags: process.env.HUB_SERVICE_URL + '/api/core/v1/tags',
  business_hours: process.env.HUB_SERVICE_URL + '/api/core/v1/business_hours',
  broadcast_tier:
    process.env.HUB_SERVICE_URL +
    '/api/core/v1/whatsapp/channel_broadcast_tier',
  server_health:
    process.env.HUB_SERVICE_URL + '/api/core/v1/whatsapp/server/health',
  group: process.env.HUB_SERVICE_URL + '/api/core/v1/whatsapp/group',
}
export const AGENTS_ALLOCATION =
  process.env.HUB_SERVICE_URL + '/api/core/v1/agent_allocation'
export const DIVISION_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/divisions'

export const BROADCAST_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/broadcasts'
export const CONTACT_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/contacts/contact_lists'
export const CONTACTS_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/contacts'
export const CONTACTS_HANDLERS =
  process.env.HUB_SERVICE_URL + '/api/core/v1/contacts/handlers'
export const CONTACTS_OBJECT =
  process.env.HUB_SERVICE_URL + '/api/core/v1/contact_objects'
export const CONTACT_BLOCK =
  process.env.HUB_SERVICE_URL + '/api/core/v1/contact_block'
export const CUSTOM_FIELD =
  process.env.HUB_SERVICE_URL + '/api/core/v1/custom_fields'
export const INTEGRATION_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/integrations'
export const UPLOAD_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/file_uploader'
export const ROOMS_URL = process.env.HUB_SERVICE_URL + '/api/core/v1/rooms' // HARD CODE
export const ROOMS_URL_V1_1 =
  process.env.HUB_SERVICE_URL + '/api/core/v1.1/rooms'
export const ROOMS_MESSAGE =
  process.env.HUB_SERVICE_URL + '/api/core/v1/messages/rooms' // HARD CODE
export const ROOMS_NOTES =
  process.env.HUB_SERVICE_URL + '/api/core/v1/notes/rooms'

export const TEMPLATES = process.env.HUB_SERVICE_URL + '/api/core/v1/templates'

export const ORGANIZATION_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/superadmins/organizations'
export const ORGANIZATION_SETTINGS_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/organizations'
export const ONGOING_CHAT =
  process.env.HUB_SERVICE_URL + '/api/core/v1/ongoing_chat'
export const USER_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/superadmins/users'
export const USER_AUTHENTICATION_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/users'
export const USER_CORE_URL = process.env.HUB_SERVICE_URL + '/api/core/v1/users'

export const REPORTS_URL = process.env.HUB_SERVICE_URL + '/api/core/v1/reports'
export const REPORTS_URL_V1_1 =
  process.env.HUB_SERVICE_URL + '/api/core/v1.1/reports'
export const REPORTS_NPS = process.env.HUB_SERVICE_URL + '/api/core/v1/nps'
export const REPORTS_NPS_V1_1 =
  process.env.HUB_SERVICE_URL + '/api/core/v1.1/nps'
export const REPORTS_CSAT = process.env.HUB_SERVICE_URL + '/api/core/v1/csat'
export const REPORTS_CSAT_V1_1 =
  process.env.HUB_SERVICE_URL + '/api/core/v1.1/csat'
export const REPORTS_CSAT_V2 = process.env.HUB_SERVICE_URL + '/api/core/v2/csat'
export const REPORTS_CES = process.env.HUB_SERVICE_URL + '/api/core/v1/ces'
export const REPORTS_CES_V1_1 =
  process.env.HUB_SERVICE_URL + '/api/core/v1.1/ces'
export const REPORTS_SETTING =
  process.env.HUB_SERVICE_URL + '/api/core/v1/system/settings'
export const REPORTS_CHAT_HISTORY =
  process.env.HUB_SERVICE_URL + '/api/core/v1/chat_histories'

export const QISCUS_INTEGRATION_URL =
  process.env.HUB_SERVICE_URL + '/api/v1/superadmins/integrations/qiscus'

export const TOKEN_UPDATE_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/auths/notification_credentials'

export const ROOM_URL = {
  get: 'https://virtserver.swaggerhub.com/udnpico/chat/1.0.0/api/core/v1/rooms',
}

export const SEND_MESSAGE_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/messages'
export const REQUEST_OLD_DATA_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/messages/request_old_data'

export const TICKET_URL = process.env.HUB_SERVICE_URL + '/api/core/v1/tickets'
export const SCORE_URL = {
  csat: process.env.HUB_SERVICE_URL + '/api/core/v1/csat',
  nps: process.env.HUB_SERVICE_URL + '/api/core/v1/nps',
  ces: process.env.HUB_SERVICE_URL + '/api/core/v1/ces',
}

export const WABA_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/superadmins/waba'
export const WABA_LOGIN_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/superadmins/waba/login'
export const WABA_SERVER_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/superadmins/waba/server'
export const WABA_SETTINGS_URL =
  process.env.HUB_SERVICE_URL +
  '/api/core/v1/superadmins/waba/settings/application'
export const WABA_PROFILE_ABOUT_URL =
  process.env.HUB_SERVICE_URL +
  '/api/core/v1/superadmins/waba/settings/profile/about'
export const WABA_PROFILE_PICTURE_URL =
  process.env.HUB_SERVICE_URL +
  '/api/core/v1/superadmins/waba/settings/profile/photo'

export const EMBEDDED_SIGNUP_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/whatsapp_embedded_signup'
export const BILLINGS_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/billings'
export const SSO_URL = process.env.SSO_URL + '/sso_oauth/token'

export const ZOHO_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/surveys/zoho'
export const CONTACT_MASKING =
  process.env.HUB_SERVICE_URL + '/api/core/v1/masking'
export const TOOLS_URL = process.env.HUB_SERVICE_URL + '/api/core/v1/tools'
export const SYSTEM_SETTINGS =
  process.env.HUB_SERVICE_URL + '/api/core/v1/system/settings'

export const CUSTOMER_SURVEY_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/surveys/customer'

export const IG_COMMENT_ROOMS_MESSAGE =
  process.env.HUB_SERVICE_URL + '/api/core/v1/comments-rooms'
export const IG_POST =
  process.env.HUB_SERVICE_URL + '/api/core/v1/instagram/post'

export const TOKOPEDIA_PRODUCTS =
  process.env.HUB_SERVICE_URL + '/api/core/v1/tokopedia/products'
export const TOKOPEDIA_ORDERS =
  process.env.HUB_SERVICE_URL + '/api/core/v1/tokopedia/orders'

export const SHOPEE_URL = process.env.HUB_SERVICE_URL + '/api/core/v1/shopee'

// INTEGRATIONS
export const INTEGRATION_QONTAK_CRM =
  process.env.HUB_SERVICE_URL + '/api/core/v1/qontak'

export const CHAT_REPORT_SERVICE_URL =
  process.env.REPORT_SERVICE_URL + '/chat/v1'

export const SERVICE_OAUTH_URL = process.env.HUB_SERVICE_URL + '/oauth'

// AUTO RESOLVE
export const AUTO_RESOLVE_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/auto_resolve'

export const KB_BASE_URL =
  process.env.HUB_SERVICE_URL + '/api/core/v1/knowledge_bases'

export const OTP_URL = process.env.HUB_SERVICE_URL + '/api/core/v1/otp'

// WA CALL
export const CALLS_URL = process.env.HUB_SERVICE_URL + '/api/core/v1/calls'
